<template>
  <div class="dashboard workout-by-page workout-by-list-page">
    <div class="workout-by-list-heading-wrapper">
      <div class="container">
        <div class="workout-by-list-heading d-flex justify-content-between align-items-center">
          <div class="d-none d-xl-flex justify-content-start align-items-center">
            <p class="section-title uppercase">Search: {{ searchWord }}</p>
          </div>
        </div>

      </div>
    </div>
    <div class="workout-by-list">
      <div class="container">
        <div class="row workout-by-list-row" v-if="showWorkoutList">
          <div class="col-12 col-lg-4 col-xxl-3 workout-by-list-column" v-for="(item,index) in workoutItems" :key="index">
            <workoutItem class="item-mobile-layout-2" :workoutItem="item"/>
          </div>
        </div>
        <p v-if="!showWorkoutList">Sem resultados</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

//Components
import workoutItem from "@/components/workouts/workout-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

export default {
  name: "workout-search",
  data() {
    return {
      lang: this.$root.siteLang,
      workoutItems: [],
      showWorkoutList: false,
      pageTitle: this.$route.params.pageTitle,
      searchWord: this.$route.params.search
    }
  },
  components: {
    workoutItem,
    chevronLeft,
  },
  methods: {
    async loadProducts(authKey, idCategory) {
      await axios
          .get(process.env.VUE_APP_API_URL + 'products', {
            params: {
              id_category: idCategory,
              with_category_title: 0,
              lang: this.lang,
              search_by: this.searchWord
            },
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.length > 0) {
              for (var elem in response.data) {
                var exists = this.workoutItems.some(function (field) {
                  return field.id === response.data[elem].id
                });
                if (!exists) {
                  this.workoutItems.push(response.data[elem])
                }
              }
              this.showWorkoutList = true
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    async getAuthors() {
      await axios
          .get(process.env.VUE_APP_API_URL + 'authors', {
            params: {
              with_products: 1,
              lang: this.lang,
              search_by: this.searchWord
            }
          })
          .then((response) => {
            if (response.data.length > 0) {
              for (var elem in response.data) {
                if (response.data[elem].products.length > 0) {
                  for (var prod in response.data[elem].products) {
                    var exists = this.workoutItems.some(function (field) {
                      return field.id === response.data[elem]['products'][prod].id
                    });
                    if (!exists) {
                      this.workoutItems.push(response.data[elem]['products'][prod])
                    }

                  }
                }
              }
              this.showWorkoutList = true
            }
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {
      });
    },
  },
  async mounted() {
    let authKey = window.$cookies.get("authKey");
    this.workoutItems = []
    await this.loadProducts(authKey, 3);
    await this.loadProducts(authKey, 1);
    await this.getAuthors();
  },
}
</script>